
.teacher-session .main-content .section.slide {
  width: 100%;
  height: 100%;
  margin: 0;

  .progress-spinner {
    width: 100px !important;
    height: 100px !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .MuiPaper-root {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    position: relative;

    .slide-content-wrapper {
      display: inline-block;
      position: absolute;

      .slide-image {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }

      &.fit-width {
        width: 100%;

        .slide-image {
          width: 100%;
        }
      }

      &.fit-height {
        height: 100%;

        .slide-image {
          height: 100%;
        }
      }
    }

    .slide-choice {
      cursor: pointer;
      position: absolute;

      &:hover {
        outline: 4px solid rgba(159, 204, 59, 0.6);
      }
    }

    .student-action {
      position: absolute;
    }
  }
}