
.main-content {
  .section {
    margin: 8px 0;
  }

  .description-section {
    grid-area: description;
  }

  .button-section {
    grid-area: button-section;
    text-align: center;
  }
}
