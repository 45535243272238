
.teacher-session .main-content {
  .section {
    margin: 8px 0;
  }

  .slide-and-navigation {
    position: relative;
    width: calc(100vw - 24px);
    margin: 0 calc(((1280px - 24px) - 100vw) / 2);

    @media only screen and (max-width: 1280px) {
      margin: 0 -12px;
    }

    @media only screen and (min-aspect-ratio: 16/10) {
      margin: 0 calc(((1280px - 48px) - 160vh) / 2);
      width: 160vh;

      @media only screen and (max-width: 1280px) {
        margin: 0 auto;
      }
    }

    .slide-and-notes {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      overflow: hidden;

      .slide-body {
        flex-grow: 3;
        display: flex;
        flex-flow: row nowrap;

        .slide-content {
          position: relative;
          overflow: hidden;
        }

        .slide-container {
          width: 100%;
          height: 100%;
        }
      }

      .panel {
        flex-grow: 2;
        display: flex;
        flex-flow: column;

        .panel-controls {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          margin: 10px 0;

          .panel-toggle-position-button {
            margin: 0 10px;

            .MuiSvgIcon-root {
              margin: 0 4px;
            }
          }

          .panel-close-button {
            min-width: 50px;

            .MuiButton-iconSizeMedium {
              margin: 0;
            }
          }
        }

        .notes {
          height: 90%;
          overflow-y: scroll;
        }

        &.sidebar {
          background: lightgray;
          min-width: 300px;
          width: 100%;
          box-sizing: border-box;
          flex-grow: 1;
          padding: 12px;
        }

        &.footer {
          background: lightgray;
          height: 100%;
          min-height: 200px;
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          justify-content: flex-start;
          overflow: hidden;
          padding: 12px;
        }
      }
    }

    .slide-navigation.with-notes {
      display: grid;
      grid: "navigation notes-control" / 1fr 170px;

      .MuiPaper-root.MuiMobileStepper-root {
        grid-area: navigation;
      }

      .open-notes-section {
        grid-area: notes-control;
        padding: 12px 0;
      }

      .select-direction {
        margin: 0 10px;
      }
    }
  }

  .state-section {
    grid-area: state-section;
    display: grid;
    grid: "theme strand set button" / 1fr 1fr 1fr 1fr;
    margin: 100px 0;

    .reel-metadata .MuiTypography-body2 {
      font-weight: 600;
      font-size: 1rem;
    }

    .theme-data {
      grid-area: theme;
    }

    .strand-data {
      grid-area: strand;
    }

    .set-data {
      grid-area: set;
    }

    .button-section {
      text-align: right;
      grid-area: button;

      display: flex;
      justify-content: space-between;

      .MuiButton-containedSizeLarge {
        padding: 8px 12px;
      }
    }
  }
}

.MuiButtonBase-root.hidden {display: none}